import React, { useState } from "react";
import "./FileUpload.css";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import axios from "axios";
import Toast from '../../components/Toast/Toast'

function FileUpload(props) {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const onChange = async (event) => {
    setLoading(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("image", event.target.files[0]);

    // Details of the uploaded file
    //console.log(event.target.files[0]);
    const url = process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : "http://localhost:3501";

    await axios
      .post(`${url}/answers/upload`, formData)
      .then((response) => {
        const ansObj = {
          block_id: props.userData.block,
          user_id: props.userData.id,
          answer: response.data.filename,
          question_type: "file_upload",
          id_question: props.userData.id_question,
          question: props.userData.question,
        };

        props.fileCallback(ansObj);
        setLoading(false);
        setToastMessage("Archivo subido con éxito.");
        setSnackbarOpen(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {props.answer && (
        <a
          className="uploaded-file"
          target="_blank"
          rel="nonreferrer"
          href={`https://${process.env.REACT_APP_AZURE_BLOB_ACCOUNT_NAME}.blob.core.windows.net/answers/${props.answer}`}
        >
          <InsertDriveFileOutlinedIcon /> Ver archivo: {props.answer}
        </a>
      )}
      {!props.answer && (
        <input
          disabled={loading}
          className="file-upload"
          type="file"
          onChange={onChange}
        ></input>
      )}
      <Toast open={snackbarOpen} onClose={handleCloseSnackbar} message={toastMessage} />
    </>
  );
}

export default FileUpload;
