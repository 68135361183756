import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import CalendarPage from "../../components/Calendar/CalendarPage";
import ProyectoDeAprendizaje from "../ProyectoDeAprendizaje/ProyectoDeAprendizaje";
import Toast from "../../components/Toast/Toast";
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'


const Profile = () => {
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("planDeTrabajo");
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn, verifyPurchase } = useAuth()
  const [objectives, setObjectives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3501";

  useEffect(() => {
    const storedUser = Cookies.get('authUser')
    if (storedUser) {
      const user = JSON.parse(storedUser)
      if (user && user.token) {
        setAuthUser(user)
        setIsLoggedIn(true)
      } else {
        setAuthUser(null)
        setIsLoggedIn(false)
        navigate('/login')
      }
    } else {
      navigate('/login')
    }
  }, [setAuthUser, setIsLoggedIn, navigate])

  useEffect(() => {
    if (authUser?.id) {
      fetchObjectives(authUser?.id);
    } else {
      setLoading(false);
    }
  }, [authUser]);

  const fetchObjectives = async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/objectives/get-objectives`, {
        params: { userId },
      });

      const objectivesWithDates = response.data.data.objectives.map((objective) => ({
        ...objective,
        start: new Date(objective.due_date), // Transformar la fecha a objeto Date
        end: new Date(objective.due_date),   // Usar la misma fecha para inicio y fin
      }));

      setObjectives(objectivesWithDates);
    } catch (err) {
      console.error("Error al obtener objetivos:", err.message || err);
      setError("No se pudieron cargar los objetivos.");
      setToastMessage("Error al obtener objetivos.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteObjective = async (id) => {
    try {
      await axios.delete(`${url}/objectives/delete-objective/${id}`);
      setObjectives((prev) => prev.filter((goal) => goal.id !== id));
      setToastMessage("Objetivo borrado exitosamente.");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error al eliminar objetivo:", error);
      setToastMessage("Error al eliminar objetivo.");
      setSnackbarOpen(true);
    }
  };


  const addObjective = async (newObjective) => {
    try {
      const response = await axios.post(`${url}/objectives/add-objective`, {
        id_user: authUser.id,
        category: newObjective.category,
        title: newObjective.title,
        due_date: newObjective.date,
      });
      setObjectives([...objectives, response.data.data.objective]);
      setSnackbarOpen(true);
      setToastMessage("Objetivo agregado.");
    } catch (err) {
      console.error("Error al agregar objetivo:", err);
      setError("No se pudo agregar el objetivo.");
      setToastMessage("Error al agregar objetivo.");
      setSnackbarOpen(true);
    }
  };


  return (
    <div className={styles.profileContainer}>
      {/* Sección de Perfil */}
      <header className={styles.profileHeader}>
        <div className={styles.profileImage}>
          <span className={styles.profileInitials}>
            {authUser?.name?.charAt(0) || ""}
          </span>
        </div>
        <div className={styles.profileDetails}>
          <h2>
            {authUser?.name || "Usuario"} {authUser?.lastName || ""}
          </h2>
          <p>{authUser?.email || "Correo no disponible"}</p>
        </div>
      </header>

      {/* Navegación de Pestañas */}
      <nav className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === "planDeTrabajo" ? styles.activeTab : ""
            }`}
          onClick={() => setActiveTab("planDeTrabajo")}
        >
          Mi plan de trabajo
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === "proyectoAprendizaje" ? styles.activeTab : ""
            }`}
          onClick={() => setActiveTab("proyectoAprendizaje")}
        >
          Proyecto de aprendizaje
        </button>
      </nav>

      {/* Contenido de Pestañas */}
      <section className={styles.tabContent}>
        {activeTab === "planDeTrabajo" ? (
          <div className={styles.workPlan}>
            {loading ? (
              <p>Cargando objetivos...</p>
            ) : error ? (
              <p className={styles.error}>{error}</p>
            ) : (
              <CalendarPage objectives={objectives} onAddObjective={addObjective} onDeleteObjective={handleDeleteObjective} />
            )}
          </div>
        ) : (
          <div className={styles.learningProject}>
            <ProyectoDeAprendizaje />
          </div>
        )}
      </section>
      <Toast open={snackbarOpen} onClose={handleCloseSnackbar} message={toastMessage} />
    </div>

  );
};

export default Profile;
