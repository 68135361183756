import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Toast from "../Toast/Toast";

const Sidebar = ({ objectives, onAddObjective, onDeleteObjective }) => {
  const [newGoal, setNewGoal] = useState({
    category: "",
    title: "",
    date: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const [showAddGoal, setShowAddGoal] = useState(false);

  const getTagColor = (category) => {
    switch (category) {
      case "Experiencia laboral":
        return "#FFA85D"; // Naranja
      case "Networking":
        return "#68C7BF"; // Turquesa
      case "Formación":
        return "#D5F694"; // Verde claro
      case "Otro":
        return "#B0B0B0"; // Gris claro
      default:
        return "#FF6B6B"; // Rojo para valores no identificados
    }
  };


  const handleAddGoal = () => {
    if (newGoal.category && newGoal.title && newGoal.date) {
      onAddObjective(newGoal); // Llama a la función proporcionada para agregar el objetivo
      setNewGoal({ category: "", title: "", date: "" });
    } else {
      setToastMessage("Por favor, completa todos los campos.");
      setSnackbarOpen(true);
    }
  };

  return (
    <div className={styles.sidebarContainer}>
       <button
        className={styles.addGoalToggle}
        onClick={() => setShowAddGoal((prev) => !prev)}
      >
        {showAddGoal ? "Cerrar" : "+ Agregar objetivo"}
      </button>
      {showAddGoal && (

        <div className={styles.addGoal}>
          <select
            value={newGoal.category}
            onChange={(e) => setNewGoal({ ...newGoal, category: e.target.value })}
            className={styles.input}
          >
            <option value="">Seleccionar categoría</option>
            <option value="Formación">Formación</option>
            <option value="Networking">Networking</option>
            <option value="Experiencia laboral">Experiencia laboral</option>
            <option value="Otro">Otro</option>
          </select>
          <input
            type="text"
            placeholder="Escribe aquí..."
            value={newGoal.title}
            onChange={(e) => setNewGoal({ ...newGoal, title: e.target.value })}
            className={styles.input}
          />
          <input
            type="date"
            value={newGoal.date}
            onChange={(e) => setNewGoal({ ...newGoal, date: e.target.value })}
            className={styles.input}
          />
          <button onClick={handleAddGoal} className={styles.addButton}>
            Agregar objetivo
          </button>
        </div>
      )}
      <div className={styles.goalsList}>
        {objectives.slice().reverse().map((goal, index) => (
          <div key={index} className={styles.goalItem}>
            <span
              className={styles.goalCategory}
              style={{ backgroundColor: getTagColor(goal.category) }}
            >
              {goal.category}
            </span>
            <h4>{goal.title}</h4>
            <p className={styles.dateIcon}><CalendarMonthIcon fontSize="small"/> {goal.due_date}</p>
            <div className={styles.deleteContainer}>
            <button
              className={styles.deleteButton}
              onClick={() => onDeleteObjective(goal.id_objective)}
            >
              <DeleteIcon fontSize="small"/>
            </button>
            </div>
          </div>
        ))}
      </div>
      <Toast open={snackbarOpen} onClose={handleCloseSnackbar} message={toastMessage} />
    </div>
  );
};

export default Sidebar;
