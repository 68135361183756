import React from "react";
import styles from "./CalendarPage.module.css";
import Sidebar from "./Sidebar";
import CalendarWorkPlan from "./CalendarWorkPlan";

const CalendarPage = ({ objectives, onAddObjective, onDeleteObjective }) => {
  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        <p>
          Aquí podrás agregar tantos objetivos sean necesarios para dar ese paso
          profesional que necesitas, consúltalo cada que creas necesario.
        </p>
      </div>
      <div className={styles.content}>
        <Sidebar objectives={objectives} onAddObjective={onAddObjective} onDeleteObjective={onDeleteObjective} />
        <CalendarWorkPlan objectives={objectives} />
      </div>
    </div>
  );
};

export default CalendarPage;
